import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"

import "../../components/prizes.css"
import { StaticImage } from "gatsby-plugin-image"
// images

const Fishprotect = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Fish Protection Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                Fish and water infrastructure are not natural bedfellows. When
                fish swim into pipes and dam intakes, they become separated from
                their natural habitat, which impacts native populations,
                threatens biodiversity, and impedes recovery efforts for
                threatened and endangered species.
              </p>
              <p style={{ textAlign: "justify" }}>
                The U.S. Department of Energy (DOE) Water Power Technologies
                Office (WPTO), in collaboration with the U.S. Bureau of
                Reclamation,{" "}
                <a href="https://www.energy.gov/eere/articles/doe-and-bureau-reclamation-collaborate-launch-new-fish-protection-prize">
                  launched the Fish Protection Prize
                </a>{" "}
                to inspire innovators to compete for $700,000 of combined cash
                prizes and voucher support to help protect fish from these
                threats. In September 2020, three teams were selected as Grand
                Prize winners, and now these teams will work alongside
                researchers at the Pacific Northwest National Laboratory to
                advance their solutions.
              </p>
              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/fishprotection">
                    <button type="button" className="btns">
                      Follow the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <div>
            <StaticImage
              src="../../images/Fish_Protection.jpg"
              className="img-styl"
              alt="fish protection"
              loading="lazy"
            />
          </div>
          <span style={{ padding: "5px" }}></span>
          <p className="pcol-fz">
            The Fish Protection Prize sought innovative solutions, designs, and
            strategies to protect fish from water infrastructure, such as water
            diversions and pipes, and intakes at hydropower dams.
          </p>
          <p className="pcol-fz">
            in December 2019. The next three-staged prize invited competitors to
            share their innovative approach—along with a design and research
            plan—for keeping fish away from water diversions and intakes. This
            prize built on the initial Fish Exclusion Prize, run by the Bureau
            of Reclamation, released on March 6, 2019. The winners of the first
            prize{" "}
            <a href="https://www.usbr.gov/newsroom/stories/detail.cfm?RecordID=68883">
              were announced
            </a>
          </p>
          <p className="pcol-fz">
            Competitors proposed solutions for any fish species found in the
            United States and that could be applied to river and irrigation
            canal diversions, unscreened diversion pipes for irrigation or
            municipal water supplies, cooling water intakes of power plants, or
            dam intakes. New ideas for addressing fish exclusion or improvements
            to existing technologies were also welcome.
          </p>
          <p className="pcol-fz">
            While the prize solicited solutions of all shapes and sizes,
            organizers identified the following six topic areas as having the
            greatest potential for successful and cost-effective fish exclusion:
          </p>
          <ol className="pcol-fz">
            <li>Sensory deterrents (e.g., acoustic and visual)</li>
            <li>Turbulence and velocity-based deterrents</li>
            <li>Combination stimulus barriers</li>
            <li>Diversion or intake layouts/geometry</li>
            <li>Fish screen materials or coatings</li>
            <li>Fish screen cleaning methods.</li>
          </ol>
          <div>
            <StaticImage
              src="../../images/Fish_Protection_Stages.webp"
              className="img-styl"
              alt="fish protection"
              loading="lazy"
            />
          </div>
          <p className="pcol-fz">
            The CONCEPT Stage is now closed, and 9 finalists were selected to
            enter the INCUBATION Stage. In the INCUBATION Stage, participants
            were paired with experts from Pacific Northwest National Laboratory
            (PNNL) and received up to 50 hours of technical analysis or other
            support in advance of the PITCH CONTEST. The PITCH CONTEST took
            place September 15-16, 2020 at the virtual American Fisheries
            Society meeting, and this final stage determined which of the
            finalists received the cash and voucher Grand Prizes.
          </p>
          <p className="pcol-fz">
            Watch the awards announcement, presented by Alejandro Moreno,
            Director of WPTO, and David Raff, Chief Engineer of the Bureau of
            Reclamation.
          </p>
          <hr />
        </div>

        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>

            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>CONCEPT Stage Open: </b>
                January 27, 2020
              </li>
              <li>
                <b>CONCEPT Stage Close:</b>
                May 13, 2020
              </li>
              <li>
                <b>CONCEPT Stage Winner Notification: </b>
                June 30, 2020
              </li>
              <li>
                <b>INCUBATION Stage Open: </b>
                June 30, 2020
              </li>
              <li>
                <b>INCUBATION Submission Close: </b>
                August 30, 2020
              </li>
              <li>
                <b>PITCH CONTEST Stage: </b>
                September 15-16, 2020
              </li>
              <li>
                <b>Winners Announced: </b>
                September 25, 2020
              </li>
            </ul>
            <p className="pcol-fz">
              <b>
                All dates are subject to change, including contest openings,
                deadlines, and announcements. Final dates for the opening of the
                CONCEPT, INCUBATION, and PITCH CONTEST stages will be posted
                here and on the HeroX site.
              </b>
            </p>
            <hr />
            <p className="ps">
              Sign up for updates on our{" "}
              <a href="https://www.herox.com/fishprotection">
                HeroX challenge page
              </a>
              .
            </p>
          </div>
          <hr />
        </div>
        <div className="container">
          <h1 className="mac_h1">Who could participate?</h1>
          <hr />
          <p className="pcol-fz">
            Calling all innovators, visionaries, and enthusiasts with a passion
            for preservation! Be part of a trailblazing contest sparking
            tomorrow’s marine life-energy solutions. Citizens or permanent
            residents of the United States and private or non-federal public
            entities, such as townships, tribes, corporations, and other
            organizations that are incorporated in and maintain a primary place
            of business in the United States are invited to compete in the
            American-Made Challenge network. Individuals can compete alone or as
            a member of a group.
          </p>
        </div>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <div>
            <StaticImage
              src="../../images/gray-and-orange-fish.jpg"
              className="img-styl"
              alt="gray and orange fish"
              loading="lazy"
            />
          </div>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/fishprotection">
              <button type="button" class="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Fishprotect
